import { useEffect } from 'react'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import { SidePanelItem } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './SidePanel.module.scss'

export const SidePanel = ({ options, handleBack }) => {
	const { focusKey, ref } = useFocusable({
		focusKey: 'SIDE_PANEL',
		isFocusBoundary: true,
		preferredChildFocusKey: 'SIDE_PANEL_ITEM_0',

		onBackPress: handleBack,
	})

	useEffect(() => {
		setFocus(focusKey)
	}, [])

	return (
		<div className={styles.sidepanel_container}>
			<FocusContext.Provider value={focusKey}>
				<div ref={ref} className={styles.sidepanel}>
					{options.map((option, i) => (
						<SidePanelItem {...option} index={i} />
					))}
				</div>
			</FocusContext.Provider>
		</div>
	)
}

export default SidePanel
